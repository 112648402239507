import { lazy, StrictMode, Suspense } from 'react';
import { Redirect, Router } from '@reach/router';
import moment from 'moment-timezone';
import locale from 'moment/locale/nb';
import { AuthWrapper } from 'utils/auth';

const LandingPage = lazy(() => import('containers/web/MarketOverview'));
const QuoteListPage = lazy(() => import('containers/web/QuoteListPage'));
const InstrumentPage = lazy(() => import('containers/web/InstrumentPage'));
const PortfolioPage = lazy(() => import('containers/web/PortfolioPage'));

const App = lazy(() => import('containers/pages/App'));
const PageWrapper = lazy(() => import('components/Paper/other/PageWrapper'));
const FragmentsWrapper = lazy(() => import('containers/web/FragmentsWrapper'));
const WebWrapper = lazy(() => import('components/web/App'));

const PageListing = lazy(() => import('containers/pages/PageListing'));
const FrontPage = lazy(() => import('containers/pages/FrontPage'));
const IndexOverviewFragment = lazy(() =>
  import('containers/pages/IndexOverviewFragment'),
);

const MarketsPageOne = lazy(() => import('containers/pages/MarketsPageOne'));
const MarketsPageTwo = lazy(() => import('containers/pages/MarketsPageTwo'));
const UnlistedPage = lazy(() => import('containers/pages/UnlistedPage'));

const FundsPage = lazy(() => import('containers/pages/FundsPage'));

const CommoditiesPage = lazy(() => import('containers/pages/CommoditiesPage'));
const CurrenciesPage = lazy(() => import('containers/pages/CurrenciesPage'));
const InterestsPage = lazy(() => import('containers/pages/InterestsPage'));
const InternationalStocksPageOne = lazy(() =>
  import('containers/pages/InternationalStocksPageOne'),
);
const InternationalStocksPageTwo = lazy(() =>
  import('containers/pages/InternationalStocksPageTwo'),
);
const StocksPageOne = lazy(() => import('containers/pages/StocksPageOne'));

const StocksPageTwo = lazy(() => import('containers/pages/StocksPageTwo'));

const DerivativesPage = lazy(() => import('containers/pages/DerivativesPage'));
const QuoteFragment = lazy(() => import('containers/web/QuoteFragment'));
const QuoteTitlebarFragment = lazy(() =>
  import('containers/web/QuoteTitlebarFragment'),
);
const WinnersFragment = lazy(() => import('containers/web/WinnersFragment'));
const LosersFragment = lazy(() => import('containers/web/LosersFragment'));
const WinnersLosersFragment = lazy(() =>
  import('containers/web/WinnersLosersFragment'),
);
const StockGraphFragment = lazy(() =>
  import('containers/web/StockGraphFragment'),
);
const GraphWidgetFragment = lazy(() =>
  import('containers/web/GraphWidgetFragment'),
);

// Load norwegian locale
moment.updateLocale('nb', locale);
moment.locale('nb');
moment.tz.setDefault('Europe/Oslo');

function Routes() {
  return (
    <StrictMode>
      <Suspense
        fallback={
          null /* Not catching suspended subroutes deeper in the tree causes a flash, WebWrapper children are also wrapped with a suspense */
        }
      >
        <AuthWrapper>
          <App>
            <Router>
              <WebWrapper path="/">
                <Redirect from="/" to="/NO" />
                <LandingPage path="/:view" />
                <LandingPage path="/:view/valuta" />
                <QuoteListPage path="/:view/kursliste" />
                <InstrumentPage path="/:view/instrument/:itemSector" />
                <PortfolioPage path="/:view/portfolio/:portfolioId" />
              </WebWrapper>
              <PageWrapper path="page">
                <PageListing path="/" />
                <FrontPage path="frontpage" />
                <IndexOverviewFragment path="indexoverview" />
                <IndexOverviewFragment path="indexoverview/compact" compact />
                <MarketsPageOne path="markets/1" />
                <MarketsPageTwo path="markets/2" />
                <DerivativesPage path="derivatives" />
                <UnlistedPage path="unlisted" />
                <FundsPage path="funds" />
                <CommoditiesPage path="commodities" />
                <CurrenciesPage path="currencies" />
                <InterestsPage path="interests" />
                <InternationalStocksPageOne path="international/1" />
                <InternationalStocksPageTwo path="international/2" />
                <StocksPageOne path="ose/1" />
                <StocksPageTwo path="ose/2" />
              </PageWrapper>
              <FragmentsWrapper path="fragment">
                <QuoteFragment path="quote/:itemSector" />
                <QuoteTitlebarFragment path="quote-titlebar/:itemSector" />
                <WinnersFragment path="winners/:view" />
                <LosersFragment path="losers/:view" />
                <WinnersLosersFragment path="winnerslosers/:view" />
                <StockGraphFragment path="stock-graph/:itemSector" />
                <GraphWidgetFragment path="graphwidget/:itemSector" />
              </FragmentsWrapper>
            </Router>
          </App>
        </AuthWrapper>
      </Suspense>
    </StrictMode>
  );
}

Routes.displayName = 'Routes';

export default Routes;
