import styled from '@emotion/styled';
import { Spinner } from '@oms/ui-spinner';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Box } from '@oms/ui-box';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.span`
  margin-left: 10px;
`;

const FullPageLoading = ({ AuthIFrame }: { AuthIFrame?: any }) => {
  const [showReload, setShowReload] = useState(false);
  const isFragment = window.location.href.split('/').includes('fragment');

  useEffect(() => {
    const connectionTimeout = setTimeout(() => setShowReload(true), 5000);
    return () => {
      clearTimeout(connectionTimeout);
    };
  }, []);
  return (
    <>
      {AuthIFrame && AuthIFrame}
      {!isFragment && (
        <>
          <Helmet>
            <style>{`
          body,
          #root,
          #root * {
            white-space: nowrap;
            background-color: #01122A;
            color: white;
            font-family: PublicSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          }
          body {
            margin: 0;
          }
        `}</style>
          </Helmet>
          <Container>
            <Spinner trackColor="#004182" segmentColor="#1488FC" size="lg" />
            <TextContainer>
              <Box fontWeight="bold">
                {AuthIFrame ? 'Autentisering...' : 'Laster inn data...'}
              </Box>
              {showReload && (
                <Box>
                  Siden kunne ikke laste, vennligst{' '}
                  <a href="/" onClick={() => window.location.reload()}>
                    prøv å laste inn siden på nytt
                  </a>
                </Box>
              )}
            </TextContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default FullPageLoading;
