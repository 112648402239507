import { useQuery } from 'react-query';
import { BLAIZE_PROFILE, COMPUTED_SECURE_URL, USER_INFO } from 'constants/urls';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import FullPageLoading from 'components/web/FullPageLoading';

const fetchMe = async () => {
  const response = await fetch(USER_INFO, {
    credentials: 'include',
  });
  return await response.json();
};

const fetchBlaizeProfile = async () => {
  const response = await fetch(BLAIZE_PROFILE, {
    credentials: 'include',
  });
  return await response.json();
};

export const useMe = () => {
  return useQuery('userinfo', fetchMe, { refetchOnMount: false });
};

export const useBlaizeProfile = () => {
  return useQuery('blaizeprofile', fetchBlaizeProfile);
};

export const useAuthIFrame = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const blaizeSessionToken = Cookies.get('blaize_session');

  const AuthIFrame = useMemo(
    () => (
      <>
        <iframe
          hidden
          style={{ display: 'none' }}
          title="marketdata-auth"
          src={`${COMPUTED_SECURE_URL}/auth/token?token=${blaizeSessionToken}`}
          onLoad={() => setIsLoaded(true)}
        ></iframe>
      </>
    ),
    // eslint-disable-next-line
    [],
  );
  return { AuthIFrame, isLoaded };
};

export const AuthWrapper = ({ children }: any) => {
  const { isLoaded, AuthIFrame } = useAuthIFrame();

  if (Cookies.get('blaize_session') && !isLoaded)
    return <FullPageLoading AuthIFrame={AuthIFrame} />;

  return children;
};

export const useIsLoggedIn = () => {
  const {
    data,
    isLoading,
    refetch: refetchMe,
    isFetched: isMeFetched,
  } = useMe();
  const { data: blaizeData, isLoading: isBlaizeLoading } = useBlaizeProfile();

  const { sub, FAProduct } = data?.principals?.[0] || {};
  const loggedIn: boolean = sub && sub !== 'anonymous';
  const zephrLoggedIn: boolean = !!blaizeData?.token;

  const hasRealtime: boolean =
    loggedIn &&
    (FAProduct?.includes('FAREGISTRERT') || FAProduct?.includes('FABORSPROFF'));

  useEffect(() => {
    if (isMeFetched && !loggedIn && zephrLoggedIn) {
      setTimeout(() => refetchMe(), 1000);
    }
    // eslint-disable-next-line
  }, [isMeFetched, zephrLoggedIn, loggedIn]);

  return {
    loggedIn,
    isLoading,
    isBlaizeLoading,
    hasRealtime,
    userId: sub,
  };
};
