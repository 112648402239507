import { sendResizeMessage } from 'utils/postMessage';

const onElementHeightChange = (elm: HTMLElement, callback: Function) => {
  let onElementHeightChangeTimer;
  let lastHeight = elm.clientHeight;
  let newHeight;

  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight !== newHeight) {
      callback();
    }

    lastHeight = newHeight;

    if (onElementHeightChangeTimer) {
      clearTimeout(onElementHeightChangeTimer);
    }

    onElementHeightChangeTimer = setTimeout(run, 200);
  })();
};

export const runHeightChangeWatcher = () => {
  onElementHeightChange(document.body, sendResizeMessage);
  window.addEventListener('resize', sendResizeMessage);
  setTimeout(sendResizeMessage);
};

export default runHeightChangeWatcher;
