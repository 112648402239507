import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const getEnvironment = () => {
  switch (window.location.host) {
    case 'bors.finansavisen.no':
      return 'Production';
    case 'test.webapp.fin.oms.no':
      return 'Test';
    case 'dev.webapp.fin.oms.no':
      return 'Dev';
    default:
      return null;
  }
};

function getReleaseVersion() {
  let releaseVersion;
  try {
    releaseVersion = (require('./meta.json') || {})?.releaseVersion;
  } catch (error) {
    console.warn('Could not retrieve release version', error);
  }
  return releaseVersion;
}

export function setupSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://0fa168a51a4849b6b5290b76caf933b9@o352519.ingest.sentry.io/5422662',
      environment: getEnvironment(),
      release: getReleaseVersion(),
      // Send only 1% of events to prevent us going over our quota
      sampleRate: 0.01,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  }
}
