import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import ReactDOM from 'react-dom';

import ensureIntlSetup from 'utils/ensureIntlSetup';
import runHeightChangeWatcher from 'utils/runHeightChangeWatcher';
import * as serviceWorker from './serviceWorker';
import { setupSentry } from './setupSentry';
import Routes from './routes';

setupSentry();
runHeightChangeWatcher();

const elem = document.getElementById('root');
const render = () => {
  ReactDOM.render(<Routes />, elem);
};

async function main() {
  ensureIntlSetup().then(render);
}

main();

// Boots service worker. In case of emergency, change register to unregister
// below and re-deploy. This will kill the service workers. Do NOT simply remove
// this line or delete the public/service-worker.js file from the server,
// explicitly unregistring is required, anything else is a noop.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  // Override default behavior of waiting for all tabs to close before
  // refreshing the cached files. The below callback will force a reload when
  // a new service worker is detected.
  //
  // TODO Better to ask user first, example "A new version is available, would
  // you like to reload?"?
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
