let win;
if (typeof window === 'undefined') {
  win = { location: {} };
} else {
  win = window;
}

export const ORIGIN = win.location.origin;
export const BASE_URL = `${ORIGIN}/server`;
export const MARKETDATA_URL = `https://marketdata.finansavisen.no/server`;
export const TEST_URL = `https://test.webapp.fin.oms.no/server`;
export const BASE_SECURE_URL = `${BASE_URL}/secure`;
export const MARKETDATA_SECURE_URL = `${MARKETDATA_URL}/secure`;
export const COMPONENTS_SECURE_URL = `${BASE_URL}/secure/components`;
export const COMPUTED_BASE_URL =
  window.location.hostname === 'bors.finansavisen.no'
    ? MARKETDATA_URL
    : BASE_URL;
export const COMPUTED_SECURE_URL =
  window.location.hostname === 'bors.finansavisen.no'
    ? MARKETDATA_SECURE_URL
    : BASE_SECURE_URL;

export const AUTH_LOGIN = `${BASE_SECURE_URL}/auth/connectid/login?returnUrl=${encodeURIComponent(
  window.location.origin + window.location.pathname,
)}`;
export const AUTH_LOGOUT = `${BASE_SECURE_URL}/auth/connectid/logout`;

export const USER_INFO = `${COMPUTED_SECURE_URL}/auth/me`;
export const BLAIZE_PROFILE = `${ORIGIN}/blaize/profile`;
export const WATCHLIST = `${COMPUTED_SECURE_URL}/watchlist`;

// External mediaconnect URLs
export const MEDIACONNECT_AUTH_BASE_URL =
  window.location.hostname === 'bors.finansavisen.no'
    ? 'https://connectid.no/user'
    : 'https://api-test.mediaconnect.no/login';

const currentUrl = encodeURIComponent(window.location.href);
const landOnStatus = (status: string) =>
  `${currentUrl}${
    window.location.search.length ? '%26' : '%3F'
  }login_status=${status}`;

// Put these on one line for searchability
// prettier-ignore
export const MEDIACONNECT_CHECK_LOGIN = `${MEDIACONNECT_AUTH_BASE_URL}/loginCheck?clientId=no.finansavisen.bors&returnUrl=${landOnStatus('mediaconnect_logged_in')}&errorUrl=${landOnStatus('mediaconnect_logged_out')}`;
// prettier-ignore
export const MEDIACONNECT_LOGIN = `${MEDIACONNECT_AUTH_BASE_URL}/oauth/authorize?client_id=no.finansavisen.bors&response_type=code&redirect_uri=${AUTH_LOGIN}`;
// prettier-ignore
export const MEDIACONNECT_LOGOUT = `${MEDIACONNECT_AUTH_BASE_URL}/logout?clientId=no.finansavisen.bors&returnUrl=${AUTH_LOGOUT}&errorUrl=${landOnStatus('mediaconnect_logged_out')}`;
export const FINANSAVISEN_SUBSCRIPTION_PAGE =
  'https://finansavisen.no/abonnement/bors';

export const PORTFOLIO_BASE_URL = `${COMPUTED_SECURE_URL}/portfolio`;
export const PORTFOLIO_TRANSACTIONS_BASE_URL = `${COMPUTED_SECURE_URL}/transactions`;
export const FICTIVE_PORTFOLIO_BACKEND = 'fstock';
